<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('User Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change User Information')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
        <!-- <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div class="form-group row" :class="{ 'form-group--error row': $v.user.name$error }">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('name')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="user.name"
              :class="{ 'form-input--error': $v.user.name.$error }"
              :state="$v.user.name.$error===true?false:null"
            />
          </div>
        </div>
        <div class="form-group row" :class="{ 'form-group--error row': $v.user.email.$error }">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Email')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="user.email"
              :class="{ 'form-input--error': $v.user.email.$error }"
              :state="$v.user.email.$error===true?false:null"
            />
          </div>
        </div>


        <div class="form-group row" :class="{ 'form-group--error row': $v.user.password.$error }">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Password')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="password"
              v-model="user.password"
              :class="{ 'form-input--error': $v.user.password.$error }"
              :state="$v.user.password.$error===true?false:null"
            />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('User Type')}}</label>
          <div class="col-lg-9 col-xl-6">

         
                        <b-form-group label="" >
      <select
              class="form-control form-control-lg form-control-solid"
              v-model="user.type"
            >
              <template v-for="option in options">
                <option
                  :key="option"
                  :value="option"
                  :selected="option == user.type ? true : false"
                >
                  {{ option }}
                </option>
              </template>
            </select>
    </b-form-group>
                         </div>
        </div>
        <!--begin::Form Group-->
        

        
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { required } from 'vuelidate/lib/validators'


export default {
  name: "QueueInformation",

  props: {
    user: {
        type: Object,
        required: true,
    },
  },
  data() {
    return {
      createData: [],
      options: ["admin", "finance","agent"],
    };
  },
  validations:{
    user:{
      name:{
        required
      },
      email:{
        required
      },
      password:{
        required
      },
      type:{
        required
      }


    }
  },
  async mounted() {
    
  },
  methods: {
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch('users/update', this.user);

        Swal.fire({
          title: "",
          text: "The queue has been updated successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);

        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)}




    },
    cancel() {
    },
  },
};
</script>


<style scoped>
.form-group--error{
  color: rgba(255, 0, 0, 0.8);
  
}
.form-input--error{
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus{
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error{
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>