<template>
  <div class="d-flex flex-row" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex" :no-nav-style="true">
        <b-tab active>
          <Information :user="user"></Information>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Information from "@/view/pages/Users/view-comp/Information";

export default {
  name: "ViewUser",

  components: {
    Information,
  },

  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    user() {
      return this.users.find((user) => user.id == this.$route.params.id) || {};
    },
    ...mapState({
      users: (state) => state.users.users,
    }),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "/admin/users" },
      { title: "View User" },
    ]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
};
</script>
